import { Component, Input, ViewChild } from '@angular/core';
import { VpsService } from '../../services/vps.service';
import { NbSortDirection, NbSortRequest, NbTreeGridDataSource, NbTreeGridDataSourceBuilder } from '@nebular/theme';
import { Vps } from '../../models';

@Component({
  selector: 'app-contabo-vps-table',
  templateUrl: './contabo-vps-table.component.html',
  styleUrls: ['./contabo-vps-table.component.scss']
})
export class ContaboVpsTableComponent {
  defaultColumns = ['instanceId', 'ip', 'created_at'];
  customColumn = 'actions';
  allColumns = [...this.defaultColumns, this.customColumn];

  dataSource: NbTreeGridDataSource<any>;

  sortColumn: string = '';
  sortDirection: NbSortDirection = NbSortDirection.NONE;

  labels: { [key: string]: any } = {
    instanceId: 'Instance Id',
    ip: 'Public IP',
    created_at: 'Created date',
  }
  vpsData: any

  @ViewChild('item') accordion: any;
  @Input() usedVps: Vps[];
  @Input() onStartUsingVps: (ip:string) => void

  constructor(private dataSourceBuilder: NbTreeGridDataSourceBuilder<any>, private vpsService: VpsService) {
    // this.dataSource = this.dataSourceBuilder.create(this.data);
  }

  ngOnInit() {
    this.vpsService.getContaboAllVps().then((data) => {
      const listVps: any[] = data.data;
      this.vpsData = listVps.map((vps) => ({
        instanceId: vps.instanceId,
        ip: vps?.ipConfig?.v4?.ip || '',
        created_at: vps.createdDate,
        isUsed: !!this.usedVps.find((v) => vps.instanceId.toString() === v.contabo_id)
      }));
      this.prepareDataSource();
      this.changeSort({direction: NbSortDirection.DESCENDING, column: 'created_at'})
    }).catch(console.log);
  }

  toggle() {
    this.accordion.toggle();
  }

  onStartUsing(ip: string) {
    this.onStartUsingVps && this.onStartUsingVps(ip);
  }

  prepareDataSource() {
    const d: { data: Vps }[] = this.vpsData?.map((el: Vps) => ({
      data: el
    }));
    this.dataSource = this.dataSourceBuilder.create(d);
  }

  changeSort(sortRequest: NbSortRequest): void {
    this.dataSource.sort(sortRequest);
    this.sortColumn = sortRequest.column;
    this.sortDirection = sortRequest.direction;
  }

  getDirection(column: string): NbSortDirection {
    if (column === this.sortColumn) {
      return this.sortDirection;
    }
    return NbSortDirection.NONE;
  }

}
