import { Pipe, PipeTransform } from '@angular/core';
import { Character } from '../models';

@Pipe({
  name: 'characterSort'
})
export class CharacterSortPipe implements PipeTransform {

  transform(array: Character[] | null): Character[] | null {
    if (array == null) return array;

    return array.sort((a: Character, b: Character) => {
      if (a.name > b.name) {
        return -1;
      } else if (a.name < b.name) {
        return 1;
      } else {
        return 0;
      }
    });
  }
}
