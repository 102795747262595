import { Injectable } from '@angular/core';
import { AxiosResponse } from 'axios';
import { environment } from '../../environments/environment';
import { Playlist, PlaylistCreation } from '../models';
import { PlaylistActions } from '../store/actions/playlist.actions';
import { Store } from '@ngrx/store';
import { AxiosInstance } from './axios-instance.service';

@Injectable({
  providedIn: 'root'
})
export class PlaylistService {

  private readonly apiUrl = environment.apiUrl;
  private readonly axiosInstance: AxiosInstance;

  constructor(private store: Store) {
    this.axiosInstance = new AxiosInstance(this.apiUrl, 'Playlist service');
  }

  getAll(): Promise<AxiosResponse<Playlist[]>> {
    return this.axiosInstance.get('/playlist');
  }

  removePlaylist(playlistId: number) {
    return this.axiosInstance.delete(`/playlist/${playlistId}`);
  }

  createPlaylist(playlist: PlaylistCreation) {
    return this.axiosInstance.post('/playlist', playlist).then(({ data }) => {
      this.store.dispatch(PlaylistActions.addPlaylist({ playlist: data }));
    }).catch(console.log);
  }

  updatePlaylist(playlist: Playlist) {
    return this.axiosInstance.put('/playlist', playlist).then(({ data }) => {
      this.store.dispatch(PlaylistActions.updatePlaylist({ playlist: data }));
    }).catch(console.log);
  }

  saveOrUpdatePlaylist(editablePlaylist: PlaylistCreation | Playlist) {
    return editablePlaylist && editablePlaylist?.id ?
      this.updatePlaylist(editablePlaylist as Playlist) :
      this.createPlaylist(editablePlaylist as PlaylistCreation);
  }
}
