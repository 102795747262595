import { Injectable } from '@angular/core';
import { AxiosResponse } from 'axios';
import { environment } from '../../environments/environment';
import { Observable, from } from 'rxjs';
import { AxiosInstance } from './axios-instance.service';
import { Suggestion } from '../models/suggestion';

@Injectable({
  providedIn: 'root'
})
export class SuggestionService {
  private readonly apiUrl = environment.apiUrl + '/suggestion';
  private readonly axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = new AxiosInstance(this.apiUrl, "Suggestion service");
  }

  getAll(): Promise<AxiosResponse<Suggestion[]>> {
    return this.axiosInstance.get(this.apiUrl);
  }

  getAll$(): Observable<Suggestion[]> {
    return from(this.axiosInstance.get<Suggestion[]>(this.apiUrl).then(response => response.data));
  }

  giveSuggestion(suggestion: String): Promise<AxiosResponse<Suggestion[]>> {
    return this.axiosInstance.post(this.apiUrl, { suggestion });
  }

  deleteSuggestions(ids: number[]): Promise<AxiosResponse<any>> {
    return this.axiosInstance.delete(`${this.apiUrl}/`, { data: { ids } });
  }
}
