import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment, { Moment } from 'moment';


@Component({
  selector: 'app-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimepickerComponent implements OnInit {
  @Output() onChange = new EventEmitter<Moment>();
  @Input('defaultValue') defaultValue: number;

  selectedTime: Moment;

  constructor() {
   this.selectedTime = moment().startOf('day')
  }

  ngOnInit() {
    if (this.defaultValue) {
      this.selectedTime = moment().startOf('day').add(this.defaultValue, 'milliseconds');
    }
  }

  onSelectTime(data: any) {
    if (data.save) {
      this.onChange.emit(data.time);
    }
  }
}
