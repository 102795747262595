import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-chat-rectangle',
  templateUrl: './chat-rectangle.component.html',
  styleUrls: ['./chat-rectangle.component.scss']
})
export class ChatRectangleComponent {
  @Input() isVertical: boolean = false;
  @Input() size: number = 200;
  @Input() blocked: boolean = false;

  getIcon() {
    return `assets/${ this.blocked ? "blocked-" : "" }chat-icon.svg`
  }

  getTooltip() {
    return this.blocked
      ? "Your monthly token allowance has been used up.\n The chatbot will no longer respond until your next billing cycle begins"
      : "Chatbot is enabled";
  }
}
