import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { AxiosInstance } from './axios-instance.service';
import { Observable, from } from "rxjs";
import { DashboardResponse } from "models/dashboard";

@Injectable({ providedIn: 'root' })
export class DashboardService {

  private readonly apiUrl = `${environment.apiUrl}/statistic`;
  private readonly axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = new AxiosInstance(this.apiUrl, "Dashboard service");
  }

  getDashboard$(): Observable<DashboardResponse[]> {
    return from(this.axiosInstance.get('/').then(res => res.data));
  }
}
