import { Injectable } from '@angular/core';

interface LogData {
  id?: string;
  group?: string;
  reason?: string;
  log: any;
}

@Injectable({
  providedIn: 'root',
})
export class LogService {
  constructor() {}

  logToConsole(log: LogData): void {
    console.group(`${log.group} - Log: ${log.id}`);
    console.log(`Reason: ${log.reason}`);
    console.log('Log:', log.log);
    console.groupEnd();
  }
}
