import { Component } from '@angular/core';
import { NbWindowRef } from '@nebular/theme';
import { map, Observable, Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SelectOption, Stream, User, Character } from '../../models';
import { select, Store } from '@ngrx/store';
import { characterFeature } from '../../store/reducers/characters.reducers';
import { countTokens } from '../../utils/characterUtils';

@Component({
  selector: 'app-stream-character-modal',
  templateUrl: './stream-character-modal.component.html',
  styleUrls: ['./stream-character-modal.component.scss']
})
export class StreamCharacterModalComponent {
  user: User;
  stream: Stream;
  character: Character;
  characterForm: FormGroup;
  isLoading: boolean = false;
  onError: (message: string) => void;
  onSubmit: (...args: any) => void;
  value: number = 0;
  subscription: Subscription;
  charactersOption$:  Observable<SelectOption[]>;
  resourceCharacterId: number;
  scenarioTokens: number = 0;

  constructor(
    private windowRef: NbWindowRef,
    private fb: FormBuilder,
    private store: Store,
  ) {
    this.charactersOption$ = this.store.pipe(
      select(characterFeature.getAllCharacters),
      map(characters => characters.map(({id, name}) => ({value: id, name})))
    );
    this.characterForm = this.fb.group({
      scenario: ['', [Validators.maxLength(1500)]],
      character: ['', Validators.required],
    });

    this.characterForm.get('scenario')?.valueChanges.subscribe(value => {
      this.scenarioTokens = countTokens(!value ? value : value + ". Scenario: ");
    })
  }

  ngOnInit(): void {
    if (this.stream && this.stream.character) {
      this.setUpCharacterData(this.stream.character.id, this.stream.scenario);
    }
  }

  setUpCharacterData(characterId: string, scenario: string = ''): void {
    this.characterForm.patchValue({
      character: characterId || '',
      scenario: scenario || '',
    })
  }

  generateNewCharacter() {
    let { scenario, character } = this.characterForm.value;

    return {
      ...(this.resourceCharacterId ? { id: this.resourceCharacterId } : {}),
      characterId: character,
      scenario: scenario,
    }
  }

  submit() {
    if (this.characterForm.valid) {
      this.onSubmit({ ...this.generateNewCharacter(), streamId: this.stream.id, stream: this.stream });
      this.closeModal();
    } else {
      this.onError('Type all required fields');
    }
  }

  closeModal() {
    this.windowRef.close();
  }
}
