import { DraftCharacter } from '../models';

export const characterPresets: DraftCharacter[] = [
  {
    "id": "",
    "name": "character 1",
    "description": "description 1",
    "personality": "personality 1",
    "firstMes": "firstMes 1",
    "mesExample": "",
    "metadata": {
      "temperature": 0.87,
      "frequency_penalty": -0.16,
      "stop": [
        "***"
      ]
    },
    "aiModel": "openrouter/qwen/qwen-2-7b-instruct",
    "userId": 0,
  },
  {
    "id": "",
    "name": "character 2",
    "description": "description 2",
    "personality": "personality 2",
    "firstMes": "firstMes 2",
    "mesExample": "",
    "metadata": {
      "temperature": 0.9,
      "stop": [
        "test"
      ]
    },
    "aiModel": "openrouter/gryphe/mythomax-l2-13b",
    "userId": 0,
  },
  {
    "id": "",
    "name": "character 3",
    "description": "description 3",
    "personality": "personality 3",
    "firstMes": "firstMes 3",
    "mesExample": "",
    "metadata": {
      "stop": [
        "***",

      ]
    },
    "aiModel": "openrouter/qwen/qwen-2-7b-instruct",
    "userId": 0,
  }
];

export const defaultCharacterPreset = "Creation From Scratch";
