import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AxiosInstance } from './axios-instance.service';
import { DashboardConfigChange } from 'models/events';
import { Observable, from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private readonly apiUrl = `${environment.apiUrl}/config`;
  private readonly axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = new AxiosInstance(this.apiUrl, "Config service");
  }

  updateConfig$(payload: DashboardConfigChange): Observable<any> {
    return from(this.axiosInstance.put('/', payload).then(res => res.data));
  }

}
