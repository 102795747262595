import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  NbToastrService,
  NbWindowService
} from '@nebular/theme';
import { select, Store } from '@ngrx/store';
import { DataState } from 'enum/data-state.enum';
import {
  Observable,
} from 'rxjs';
import { User } from '../../models';
import { buttonsConfig } from 'models/nb';
import { characterFeature } from '../../store/reducers/characters.reducers';
import { showWarning } from '../../helpers/nb';
import {
  TriggerWixCompareModalComponent
} from '../../components/trigger-wix-compare-modal/trigger-wix-compare-modal.component';
import { ConfirmModalComponent } from '../../components/confirm-modal/confirm-modal.component';
import { AdminService } from '../../services/admin.service';

@Component({
  selector: 'app-admin-settings',
  templateUrl: './admin-settings.component.html',
  styleUrls: ['./admin-settings.component.scss']
})
export class AdminSettingsComponent implements OnInit, OnDestroy {

  readonly pageTitle = 'Admin Settings';
  readonly DataState = DataState;
  error$: Observable<string | null>;
  pageState$: Observable<DataState>;
  user: User;

  constructor(
    private windowService: NbWindowService,
    private store: Store,
    private toastrService: NbToastrService,
    private adminService: AdminService,
  ) {
    this.pageState$ = this.store.pipe(select(characterFeature.selectPageState));
    this.error$ = this.store.pipe(select(characterFeature.getCharactersPageError));
  }

  ngOnInit(): void {
  }

  triggerWixCompare() {
    this.windowService.open(TriggerWixCompareModalComponent, {
      title: `Trigger Wix Update`,
      buttons: buttonsConfig,
      context: {
        onError: showWarning.bind(this, this.toastrService)
      }
    });
  }


  triggerUpdateAllVps() {
    this.windowService.open(ConfirmModalComponent, {
      title: `Update all Vps`,
      buttons: buttonsConfig,
      context: {
        textContent: `This action will permanently trigger all VPS updating`,
        actionButtonText: "Update All",
        actionCallback: () => this.updateAllVps()
      },
    });
  }

  updateAllVps() {
    this.adminService.updateAllVps();
    showWarning(
      this.toastrService ,
      "updating all VPS",
      7000
    )
  }

  ngOnDestroy() {
  }
}
