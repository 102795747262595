import { Injectable } from '@angular/core';
import { AxiosResponse } from 'axios';
import { environment } from '../../environments/environment';
import { Stream, StreamCreation } from '../models';
import { AxiosInstance } from './axios-instance.service';

@Injectable({
  providedIn: 'root'
})
export class StreamService {

  private readonly apiUrl = environment.apiUrl + '/stream';
  private readonly axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = new AxiosInstance(this.apiUrl, "Stream service");
  }

  private createStream(stream: StreamCreation) {
    return this.axiosInstance.post(`${this.apiUrl}`, stream);
  }

  private updateStream(stream: Stream) {
    return this.axiosInstance.put(`${this.apiUrl}`, stream);
  }

  createOrUpdateStream(stream: Stream | StreamCreation) {
    if (!stream.id) {
      return this.createStream(stream as StreamCreation);
    } else {
      return this.updateStream(stream as Stream);
    }
  }

  fetchStreams(): Promise<AxiosResponse<Stream[], any>> {
    return this.axiosInstance.get(this.apiUrl);
  }

  stopUserStreams(userEmail: string) {
    return this.axiosInstance.get(`${this.apiUrl}/stop/all/${userEmail}`);
  }

  startStreams(streamId: number) {
    return this.axiosInstance.get(`${this.apiUrl}/start/${streamId}`);
  }

  stopStreams(streamId: number) {
    return this.axiosInstance.get(`${this.apiUrl}/stop/${streamId}`);
  }

  fetchErrorsStreams() {
    return this.axiosInstance.get(`${this.apiUrl}/error`);
  }

  assignCharacter(characterId: string, scenario: string, streamId: number) {
    return this.axiosInstance.post<boolean>(`${this.apiUrl}/${streamId}/character`, { scenario, characterId });
  }

  editCharacter(characterId: string, scenario: string, streamId: number) {
    return this.axiosInstance.patch<boolean>(`${this.apiUrl}/${streamId}/character`, { scenario, characterId });
  }

  unassignCharacter(streamId?: number) {
    return this.axiosInstance.delete<boolean>(`${this.apiUrl}/${streamId}/character`);
  }
}
