import axios, { AxiosInstance as Axios, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { LogService } from './log.service';

export class AxiosInstance {
  private axios: Axios;
  private logService: LogService;

  constructor(baseURL: string, group: string) {
    const isLogsRestEnabled = !!localStorage.getItem("logs-rest");
    this.logService = new LogService();

    this.axios = axios.create({
      baseURL,
    });

    this.axios.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        if (isLogsRestEnabled) {
          const cleanedObj = Object.fromEntries(
            Object.entries({ params: config.params, data: config.data }).filter(([key, value]) => value !== '' && value !== null && value !== undefined)
          );
          this.logService.logToConsole({
            group: group,
            id: config.url,
            log: cleanedObj,
            reason: config.method?.toUpperCase(),
          });
        }

        const sessionToken = localStorage.getItem('sessionToken');
        const supportMode = sessionStorage.getItem('supportMode');
        const supportUserId = sessionStorage.getItem('supportUserId');

        if (sessionToken) {
          config.headers['Authorization'] = sessionToken;
        }

        if (supportMode && supportUserId) {
          config.headers['X-Support-Mode-Enabled'] = supportMode;
          config.headers['X-Support-UserId'] = supportUserId;
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  get<T = any>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.axios.get(url, config);
  }

  post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.axios.post(url, data, config);
  }

  put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.axios.put(url, data, config);
  }

  delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.axios.delete(url, config);
  }

  patch<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.axios.post(url, data, config);
  }

  defaultProxyConfig = (ipAddress: string) => ({
    headers: { "X-VPS-IP": ipAddress }
  });
}
