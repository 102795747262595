import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { of, withLatestFrom } from 'rxjs';
import { CharacterActions, CharacterChatActions } from '../actions/characters.actions';
import { Character } from '../../models';
import { CharacterService } from '../../services/character.service';
import { Store } from '@ngrx/store';
import { characterFeature } from '../reducers/characters.reducers';
import { LogService } from '../../services/log.service';


@Injectable()
export class CharactersEffect {
  getCharacterList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CharacterActions.getCharactersList),
      mergeMap(({ userUuid, type }) =>
        this.characterService.getAll$(userUuid).pipe(
          map((payload: Character[]) =>
            CharacterActions.getCharactersListSuccess({ characters: payload })
          ),
          catchError(error =>
            of(CharacterActions.getCharactersListFailure({ error }))
          )
        )
      )
    )
  );

  deleteResource$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CharacterActions.deleteCharacter),
        mergeMap((action) => this.characterService.deleteCharacter(action.id))
      ),
    { dispatch: false }
  );

  deleteResources$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CharacterActions.deleteCharacters),
        mergeMap((action) => this.characterService.deleteCharacters(action.ids))
      ),
    { dispatch: false }
  );

  sendChatMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CharacterChatActions.sendMessage),
      withLatestFrom(this.store.select(characterFeature.getChatMessages)),
      mergeMap(([{ message, character, stream, type }, chatMessages], ) => {
        const messagesToSend = chatMessages[0].role === "assistant" ? chatMessages.slice(1) : chatMessages;
        const streamId = stream && stream.id;
        return this.characterService.sendMessage$(messagesToSend, character.id, streamId, this.isChatLogsEnabled).pipe(
          map((data: any) => {
            this.logChatBotResponse(data, streamId);
            let message = data.choices[0].message;
            if (message.role === 'assistant' && !message.name) {
              message.name = character.name ? character.name.replace(/ /g, "") : character.name;
            }
            return CharacterChatActions.addMessage({message, tokens: data.usage.total_tokens})
          }),
        )
      })
    )
  );

  private logChatBotResponse(data: any, streamId: number | undefined) {
    if (this.isChatLogsEnabled) {
      const { prompt: rawPrompt = '{}', ...resp } = data
      const prompt = rawPrompt && JSON.parse(rawPrompt) || {};
      this.logService.logToConsole({
        group: 'stream chat bot',
        id: streamId || data.id,
        log: prompt,
        reason: 'Prompt Generated',
      });
      this.logService.logToConsole({
        group: 'stream chat bot',
        id: streamId || data.id,
        log: resp,
        reason: 'AI Response',
      });
    }
  }
  private isChatLogsEnabled= !!localStorage.getItem("logs-chatbot");

  constructor(
    private actions$: Actions,
    private characterService: CharacterService,
    private store: Store,
    private logService: LogService
  ) {}
}
