import { Injectable } from '@angular/core';
import { AxiosResponse } from 'axios';
import { environment } from '../../environments/environment';
import { User } from 'models/user';
import { AxiosInstance } from './axios-instance.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private readonly apiUrl = environment.apiUrl;
  private readonly axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = new AxiosInstance(this.apiUrl, "User service");
  }

  getAll(): Promise<AxiosResponse<User[]>> {
    return this.axiosInstance.get('/users');
  }

  getCurrentUser(): Promise<AxiosResponse<User>> {
    return this.axiosInstance.get('/users/current');
  }

  validateUserByWix(email: string | undefined): Promise<AxiosResponse<any>> {
    return this.axiosInstance.post('/users/validate/wix', { email });
  }

  validateAllUserByWix(): Promise<AxiosResponse<any>> {
    return this.axiosInstance.post('/users/validate/wix/all');
  }
}
