import { HttpEvent, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ActionCreator, select, Store } from '@ngrx/store';
import { UploadStatus } from 'enum/upload-status.enum';
import {
  getSourcePlatformRoute,
  getStatusFromProgress,
  isAuthCodeInPayload,
  isUploadRequiredAuth
} from 'helpers/videoStatus.helper';
import { PollingPayload, ResourceEntry, ResourceResponse } from 'models/resource';
import {
  auditTime,
  catchError,
  delay, filter, from,
  interval,
  map, merge,
  mergeMap,
  of,
  pairwise, startWith,
  switchMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs';
import { ResourceService } from 'services/resource.service';
import { VideoPageActions, VideoUploadActions } from 'store/actions/videos.actions';
import { userFeature } from 'store/reducers/user.reducers';
import { getAllResources, resourceFeature } from '../reducers/videos.reducers';
import { VideoStatusCode } from '../../enum/video-status.code';
import { AxiosError } from 'axios';

@Injectable()
export class VideosEffects {
  getResources$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VideoPageActions.getVideos),
      mergeMap(() =>
        this.resourceService.getResources$().pipe(
          map((payload: ResourceResponse<ResourceEntry>) =>
            VideoPageActions.getVideosSuccess({ payload })
          ),
          catchError((error) =>
            of(VideoPageActions.getVideosFailure({ error: error.message }))
          )
        )
      )
    )
  );

  deleteResource$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VideoPageActions.removeVideo),
        mergeMap((action) => this.resourceService.deleteResource$(action.id))
      ),
    { dispatch: false }
  );

  deleteResources$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VideoPageActions.removeVideos),
        mergeMap((action) => this.resourceService.deleteResources$(action.ids))
      ),
    { dispatch: false }
  );

  uploadLocalFile$ = createEffect(() => this.actions$.pipe(
    ofType(VideoUploadActions.uploadLocalVideo),
    withLatestFrom(this.store.select(userFeature.getUserVps)),
    mergeMap(([{ payload }, userVps]) =>
      this.resourceService.uploadFile$(userVps, payload).pipe(
        auditTime(1000),
        map((event) => this.getActionFromHttpEvent(event, payload.entry.id)),
        takeUntil(this.getVideoDeleted(payload.entry.id)),
        catchError(error => of(this.handleVideoUploadError(error, payload.entry.id, payload.entry)))
      )
    )
  ));

  uploadUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VideoUploadActions.uploadUrlVideo),
      withLatestFrom(this.store.select(userFeature.getUserVps)),
      mergeMap(([{ payload, source }, userVps]) =>
        of(undefined).pipe(
          mergeMap(() => of(VideoUploadActions.startPolling())),
          mergeMap(() => {
            if (payload?.entry?.id) {
              return of(this.resourceService.setResourceUpload(payload?.entry?.id));
            }
            return of(undefined);
          }),
          mergeMap(() => this.resourceService.uploadByUrl$(userVps, payload, source).pipe(
            map(resource => this.store.dispatch(VideoUploadActions.uploadProgressComplete({ payload: resource }))),
            map(() => VideoUploadActions.startPolling()),
            catchError(error => of(this.handleVideoUrlError(error, payload.entry.id)))
          )),
        ),
      )
    )
  );

  // @ts-ignore
  pollProgress$ = createEffect(() => this.actions$.pipe(
    ofType(VideoUploadActions.startPolling),
    withLatestFrom(this.store.select(resourceFeature.getUploadProgressEntities)),
    mergeMap(([_, storeData]) => of(this).pipe(
      delay(2000),
      switchMap(() =>
        interval(1000).pipe(takeUntil(this.actions$.pipe(ofType(VideoUploadActions.stopPolling))))
      ),
      withLatestFrom(this.store.select(userFeature.getUserVps)),
      switchMap(([action, userVps]) => this.resourceService.fetchYouTubeProgress$(userVps)),
      startWith(storeData),
      pairwise(),
    )),
    switchMap(data => this.getActionFromPolling(data)),
    catchError(_ => of()),
  ));

  restartPolling$ = createEffect(() => this.actions$.pipe(
    ofType(VideoPageActions.getVideosSuccess),
    mergeMap(({payload}) => {
      const { resources } = payload;
      const hasFailedVideos = resources.some(video =>
        (getStatusFromProgress(video.uploadStatus) === UploadStatus.ERROR
         || getStatusFromProgress(video.uploadStatus) === UploadStatus.PROCESSING
        )
        && !isUploadRequiredAuth(video)
      );
      return hasFailedVideos ? of(VideoUploadActions.startPolling()) : of(VideoUploadActions.stopPolling());
    })
  ));

  checkServiceAccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VideoUploadActions.checkServiceAccess),
      withLatestFrom(this.store.select(userFeature.getUserVps)),
      mergeMap(([{ platformName }, userVps]) =>
        of(undefined).pipe(
          mergeMap(() => this.resourceService.checkServiceAccess(userVps, platformName).pipe(
            map(() => ({ authCode: null })),
            catchError((error: AxiosError) => {
              if (error?.response?.data && typeof error.response.data === "string" && error?.response?.data.includes("Authorization required. Code: ")) {
                let elements = error?.response?.data.split("Authorization required. Code: ")[1];
                let [code, message] = elements.split("\n");
                let status = message ? Number(message.split("status: ")[1]) : undefined;
                return of({ elements, authCode: code, status })
              }
              return of({ authCode: null, error });
            })
          )),
          map((data) => VideoUploadActions.authorizationRequired(data)),
        ),
      )
    )
  );

  UpdateAccessRequiredVideos = createEffect(() =>
    this.actions$.pipe(
      ofType(VideoUploadActions.updateAccessRequiredVideos),
      withLatestFrom(this.store.pipe(select(getAllResources))),
      map(([data, videos]) => {
        videos.filter(video => video.statusCode === VideoStatusCode.ERROR_ACCESS).forEach(resource => {
          this.store.dispatch(VideoUploadActions.uploadUrlVideo({
            payload: { entry: resource, sourceId: resource.sourceKey },
            source: getSourcePlatformRoute(resource)
          }));
          }
        )

        return VideoUploadActions.startPolling();
      })
    )
  );

  constructor(
    private actions$: Actions,
    private resourceService: ResourceService,
    private store: Store,
  ) { }

  private getActionFromHttpEvent(event: HttpEvent<any>, id: number) {
    switch (event.type) {
      case HttpEventType.Sent: {
        return VideoUploadActions.uploadProgressUpdate({ payload: { [id.toString()]: { uploadStatus: 0 } } });
      }
      case HttpEventType.UploadProgress: {
        let uploadStatus = Math.round(100 * event.loaded / event.total!);
        return VideoUploadActions.uploadProgressUpdate({ payload: { [id.toString()]: { uploadStatus } } });
      }
      case HttpEventType.Response: {
        if (event.status === 200) {
          let payload: ResourceEntry = event.body as ResourceEntry;
          return VideoUploadActions.uploadProgressComplete({ payload });
        } else {
          return VideoUploadActions.uploadProgressFailure({ id });
        }
      }
      default: {
        return VideoUploadActions.uploadProgressFailure({ id });
      }
    }
  }

  private getActionFromPolling(data: PollingPayload[] | any) {
    const [previousPayload, currentPayload] = data;
    const currentPayloadLength = Object.keys(currentPayload).length;

    if (currentPayloadLength === 0 && (!previousPayload || Object.keys(previousPayload).length !== 0)) {
      return this.handleVideosUploadingVideos(previousPayload);
    }

    if (currentPayloadLength === 0) return of(VideoUploadActions.stopPolling());

    const failedAccessIds = Object
      .keys(currentPayload)
      .filter(key => currentPayload[key] && currentPayload[key].authCode
        && (previousPayload || !previousPayload[key] || !previousPayload[key].authCode)
      )
      .map(key => Number(key));

    return from(Promise.all(failedAccessIds.map(id => this.resourceService.setResourceAccessErrorUpload(id))))
      .pipe(
        catchError(error => {
          console.error('Error setting resource access error:', error);
          return of(undefined);
        }),
        map(() => {
          if (previousPayload && isAuthCodeInPayload(previousPayload) && !isAuthCodeInPayload(currentPayload)) {
            return VideoUploadActions.authorizationRequired({ authCode: null });
          }
          return VideoUploadActions.uploadProgressUpdate({ payload: currentPayload });
        }),
      );
  }

  handleVideosUploadingVideos(data: any ) {
    return this.store.pipe(select(getAllResources)).pipe(
      map(resources => resources
        .filter(video => video.statusCode === VideoStatusCode.UPLOADING
          || (video.statusCode === VideoStatusCode.PENDING_UPLOADING && data && data[video.id].oldPull)
        )
        .map(video => video.id)
      ),
      mergeMap(ids => from(ids).pipe(
        mergeMap(id => this.resourceService.setResourceErrorUpload(id).then(() => id)),
        map(id => this.store.dispatch(VideoUploadActions.uploadProgressFailure({ id })))
      )),
      map(() => VideoUploadActions.stopPolling())
    )
  }

  private handleVideoUrlError(error: any, id: number) {
    if (!error.error.includes("Authorization required") && !error.error.includes("504 Gateway Time-out")) {
      this.resourceService.setResourceErrorUpload(id);
      return VideoUploadActions.uploadUrlVideoFailed({error, id});
    }
    return VideoUploadActions.startPolling()
  }

  private handleVideoUploadError(error: any, id: number, entry?:  ResourceEntry) {
    if (error?.response?.status === 413 && entry) {
      this.resourceService.setResourceErrorUpload(id, VideoStatusCode.ERROR_SIZE);
      return VideoUploadActions.uploadResourceToBig({id});
    } else if (error?.response?.data
      && typeof error.response.data === "string"
      && !error.response.data.includes("504 Gateway Time-out")
    ) {
      this.resourceService.setResourceErrorUpload(id);
      return VideoUploadActions.uploadProgressFailure({id});
    } else {
      return VideoUploadActions.startPolling();
    }
  }

  getAction(action: ActionCreator) {
    return this.actions$.pipe(
      ofType(action),
      startWith({ type: '[Default] No Action' }),
    );
  }

  getVideoDeleted(entryId: number) {
    return merge(
      this.getAction(VideoPageActions.removeVideo).pipe(
        filter((action: any) => action.id === entryId)  // Cancel if the same entry ID is removed
      ),
      this.getAction(VideoPageActions.removeVideos).pipe(
        filter((action: any) => action.ids && action.ids.includes(entryId))  // Cancel if the same entry ID is removed
      )
    );
  }
}
