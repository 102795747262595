<div>
  <input
    nbInput
    type="text"
    [nbTimepicker]="timepicker"
    [ngModel]="selectedTime"
    (ngModelChange)="onSelectTime($event)"
  />
  <nb-timepicker #timepicker (onSelectTime)="onSelectTime($event)"/>
</div>
