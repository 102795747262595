export interface AdminSettingOption {
  type: "number" | "time" | "boolean" | undefined;
  defaultValue: number;
  label: string;
}

interface AdminSettingOptionsInterface {
  [key: string]: AdminSettingOption;
}

export const AdminSettingOptions: AdminSettingOptionsInterface = {
  CHATBOT_LOW_ACTIVITY_INTERACTION_TIME: { type: "time", defaultValue: 1800000, label: "Chatbot interaction time" },
  CHATBOT_MAX_COST_USAGE: { type: "number", defaultValue: 3.0, label: "Chatbot cost limitation" },
}

type AdminSettingOptionKeys = keyof typeof AdminSettingOptions;

export interface ConfigOption {
  key: AdminSettingOptionKeys,
  value: string
}

export interface EditableConfigOption {
  [key: AdminSettingOptionKeys | string] :{
    type: "number" | "time" | "boolean" | undefined;
    value: any,
    label: string;
  }
}

