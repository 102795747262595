import { createActionGroup, props } from '@ngrx/store';
import { Stream, ResourceResponse } from '../../models';

export const StreamActions = createActionGroup({
  source: 'Stream',
  events: {
    'Fetch Streams': props<{ streams: ResourceResponse<Stream[]> }>(),
    'Get Streams': props<{ streams: Stream[] }>(),
    'Add Stream': props<{ stream: Stream }>(),
    'Update Stream': props<{ stream: Stream }>(),
    'Delete Stream': props<{ id: number }>(),
  }
});
