import { createFeature, createReducer, on } from '@ngrx/store';
import { PlaylistActions } from '../actions/playlist.actions';
import { Playlist } from '../../models';

export type PlaylistState = Playlist[] | [];

const initialPlaylistState: PlaylistState = [];

export const playlistFeature = createFeature({
  name: 'playlist',
  reducer: createReducer(
    initialPlaylistState,
    on(PlaylistActions.fetchPlaylists, (state, {playlists}) => (playlists)),
    on(PlaylistActions.addPlaylist, (state, {playlist}) => ([...state, playlist])),
    on(PlaylistActions.deletePlaylist, (state, {id}) => (state.filter((pl) => id !== pl.id))),
    on(PlaylistActions.updatePlaylist, (state, {playlist}) => (state.map((pl) => {
      return pl.id === playlist.id ? {...pl, ...playlist} : pl
    })))),
});
