import { Injectable } from '@angular/core';
import { AxiosResponse } from 'axios';
import { environment } from '../../environments/environment';
import { DraftVps, Vps } from '../models';
import { Observable, from } from 'rxjs';
import { VpsDetailsDto } from '../models/vpsDetailsDto';
import { AxiosInstance } from './axios-instance.service';

@Injectable({
  providedIn: 'root'
})
export class VpsService {
  private readonly apiUrl = environment.apiUrl;
  private readonly axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = new AxiosInstance(this.apiUrl, 'VPS service');
  }

  getAll(): Promise<AxiosResponse<Vps[]>> {
    return this.axiosInstance.get('/vps');
  }

  getVpsDetails(vpsId: number): Promise<AxiosResponse<VpsDetailsDto>> {
    return this.axiosInstance.get(`/vps/${vpsId}`);
  }

  getAll$(): Observable<Vps[]> {
    return from(this.axiosInstance.get<Vps[]>('/vps').then(response => response.data));
  }

  createNewVps(newVpsData: DraftVps) {
    return this.axiosInstance.post('/vps', newVpsData);
  }

  updateVps(updatedVpsData: DraftVps) {
    return this.axiosInstance.put('/vps', updatedVpsData);
  }

  unassignUserFromVps(vpsId: string, userEmail: string): Promise<AxiosResponse<Vps>> {
    return this.axiosInstance.post('/vps/unassign', { id: vpsId, userEmail });
  }

  updateVpsSoftware(vpsId: string): Promise<AxiosResponse<Vps>> {
    return this.axiosInstance.post('/vps/update', { id: vpsId }, { timeout: 1000 * 60 * 2 });
  }

  getContaboAllVps(): Promise<AxiosResponse<object[]>> {
    return this.axiosInstance.get('/vps/all');
  }

  getAllVpsStatuses(): Promise<AxiosResponse<{ ip: string, status: number; }[]>> {
    return this.axiosInstance.get('/vps/all/status');
  }

  getVpsForCurrentUser(): Promise<Vps> {
    return this.axiosInstance.get('/vps/user/current').then(res => res.data);
  }

  checkVpsConnection(vpsPayload: DraftVps): Promise<boolean> {
    return this.axiosInstance.post('/vps/check/connection', vpsPayload).then(res => res.data);
  }

  deleteVps(vpsId: string): Promise<AxiosResponse<Vps>> {
    return this.axiosInstance.delete(`/vps/${vpsId}`).then(res => res.data);
  }

  getVpsLogs(vpsId: string): Promise<AxiosResponse<Vps>> {
    return this.axiosInstance.get(`/vps/${vpsId}/logs`).then(res => res.data);
  }
}
