import { Injectable } from '@angular/core';
import { AxiosResponse } from 'axios';
import { environment } from '../../environments/environment';
import { AiModel } from '../models/ai-model';
import { Observable, from } from 'rxjs';
import { AxiosInstance } from './axios-instance.service';

@Injectable({
  providedIn: 'root'
})
export class AiModelService {
  private readonly apiUrl = environment.apiUrl;
  private readonly axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = new AxiosInstance(this.apiUrl, 'AI Model Service');
  }

  getAll(): Promise<AxiosResponse<AiModel[]>> {
    return this.axiosInstance.get('/models');
  }

  getAll$(): Observable<AiModel[]> {
    return from(this.axiosInstance.get<AiModel[]>('/models').then(response => response.data));
  }
}
