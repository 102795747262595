import { createActionGroup, props } from '@ngrx/store';
import { Playlist } from '../../models';

export const PlaylistActions = createActionGroup({
  source: 'Playlist',
  events: {
    'Fetch Playlists': props<{ playlists: Playlist[] }>(),
    'Add Playlist': props<{ playlist: Playlist }>(),
    'Update Playlist': props<{ playlist: Playlist }>(),
    'Delete Playlist': props<{ id: number }>(),
  }
});
