import { Component, Input } from '@angular/core';
import { NbWindowRef, NbWindowService } from '@nebular/theme';
import { Observable, Subscription, of } from 'rxjs';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { User, Character, AiModel, buttonsConfig } from '../../models';
import { countTokens } from '../../utils/characterUtils';
import { CharacterOption, characterOption, CharacterOptions } from '../../consts/chatracter-options';
import { characterPresets, defaultCharacterPreset } from '../../consts/chatracter-presets';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-new-character-modal',
  templateUrl: './new-character-modal.component.html',
  styleUrls: ['./new-character-modal.component.scss']
})
export class NewCharacterModalComponent {
  @Input() user: User;
  @Input() character: Character;
  @Input() aiModels: AiModel[];
  onError: (message: string) => void;
  onSubmit: (...args: any) => void;

  characterForm: FormGroup;
  models$: Observable<string[]>;
  currentPreset: string = defaultCharacterPreset;
  tempPreset: string = defaultCharacterPreset;
  presets: string[] = [defaultCharacterPreset, ...characterPresets.map(({name}) => name)];
  isExpertMode: boolean = false;
  isLoading: boolean = false;
  value: number = 0;
  readonly options: CharacterOptions = characterOption;

  subscription: Subscription;
  tokenCounts = {
    name: 0,
    description: 0,
    personality: 0,
    firstMes: 0,
    questionAnswers: 0,
    model: 0,
  };

  constructor(
    private windowRef: NbWindowRef,
    private fb: FormBuilder,
    private windowService: NbWindowService,
  ) {
    this.characterForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(250)]],
      description: ['', [Validators.maxLength(1500)]],
      personality: ['', [Validators.maxLength(1500)]],
      firstMes: ['', [Validators.maxLength(200)]],
      questionAnswers: this.fb.array([]),
      model: ['', Validators.required],
      ...this.getCharacterFormSliderOptions()
    });

    this.characterForm.get('name')?.valueChanges.subscribe(value => {
      this.tokenCounts.name = countTokens(!value ? value : value + "Your name " + "system" );
    });
    this.characterForm.get('description')?.valueChanges.subscribe(value => {
      this.tokenCounts.description = countTokens(!value ? value : value + ". You are " + "assistant");
    });
    this.characterForm.get('personality')?.valueChanges.subscribe(value => {
      this.tokenCounts.personality = countTokens(!value ? value : value + ". Your personality is ");
    });
    this.characterForm.get('firstMes')?.valueChanges.subscribe(value => {
      this.tokenCounts.firstMes = countTokens(value);
    });
    this.characterForm.get('questionAnswers')?.valueChanges.subscribe(value => {
      const res = this.getFinalQuestionAnswers(value);
      this.tokenCounts.questionAnswers = countTokens(!res ? res : res + ". Example of messages: ");
    });
    this.characterForm.get('model')?.valueChanges.subscribe(value => {
      this.tokenCounts.model = value ? countTokens(value) : 0;
    });
  }

  getCharacterFormSliderOptions() {
    return Object.fromEntries(Object.entries(this.options).map(([key, { defaultValue, floor, cail, isNotSlider, mapping }]: any) => {
      return isNotSlider
        ? [key, [mapping(defaultValue), [Validators.required]]]
        : [key, [defaultValue, [Validators.min(floor), Validators.max(cail)]]];
    }));
  }

  ngOnInit(): void {
    this.models$ = of(this.aiModels.map(({name}) => name));
    if (this.character) {
      this.setUpCharacterData(this.character);
    }
  }

  ngAfterViewInit() {
    const textareas = document.querySelectorAll(`textarea[formControlName="answer"]`);
    textareas.forEach(e => {
      this.autoResize({ target: e });
    });
  }

  setUpCharacterData(character: Character): void {
    const {
      aiModel,
      description,
      firstMes,
      id,
      mesExample,
      metadata = {},
      name,
      personality,
    } = character;

    let questionAnswers = mesExample ? this.getArrayOfQuestionAnswers(mesExample) : [];
    questionAnswers.forEach(({ answer, question }: any) => this.addQuestionAnswer(answer, question));
    const sliderValues = Object.fromEntries(
      (Object.keys(this.options) as Array<keyof CharacterOptions>)
        .filter(key => metadata[key] !== undefined && metadata[key] !== null)
        .map(key => [key, this.options[key].mapping ? this.options[key].mapping!(metadata[key]) : metadata[key]])
    );
    this.isExpertMode = Object.keys(sliderValues)
      .filter(key => this.options[key as keyof CharacterOptions].expert)
      .some(key => this.options[key as keyof CharacterOptions].defaultValue !== sliderValues[key]) || false;
    this.characterForm.patchValue({
      name: name || '',
      description: description || '',
      personality: personality || '',
      id: id || '',
      metadata: metadata || {},
      firstMes: firstMes || '',
      model: aiModel,
      ...sliderValues
    });
  }

  getOptions(param: keyof CharacterOptions): CharacterOption {
    return this.options[param];
  }

  get questionAnswers() {
    return this.characterForm.get('questionAnswers') as FormArray;
  }

  addQuestionAnswer(answer?: string, question?: string) {
    this.questionAnswers.push(this.fb.group({
      question: [question || '', Validators.required],
      answer: [answer || '', Validators.required]
    }));
  }

  removeQuestionAnswer(index: number) {
    this.questionAnswers.removeAt(index);
  }

  generateNewCharacter() {
    let {
      temperature,
      frequency_penalty,
      presence_penalty,
      repetition_penalty,
      top_k,
      top_p,
      min_p,
      top_a,
      questionAnswers,
      model,
      stop,
      ...character
    } = this.characterForm.value;


    const metadata = {
      ...Object.fromEntries(Object.entries(this.options)
        .filter(([key, option]: any) => (option.expert ? this.isExpertMode : true)
          && this.characterForm.value[key] !== option.defaultValue && option.isNotSlider
        )
        .map(([key]) => [key, this.characterForm.value[key]])
      ),
      stop: stop ? this.generateStopArray(stop) : ['***']
    }
    const {
      filteredMetadata,
      ...oldCharacter
    } = this.character || {}
    return {
      ...oldCharacter,
      ...character,
      mesExample: this.getFinalQuestionAnswers(questionAnswers),
      aiModel: model,
      userId: this.user.id,
      metadata,
    };
  }

  submit() {
    if (this.characterForm.valid) {
      const character = this.generateNewCharacter();
      this.onSubmit(character);
      this.closeModal();
    } else {
      this.onError('Type all required fields');
    }
  }

  getFinalQuestionAnswers(questionAnswers: any[]) {
    return questionAnswers.reduce((acc, e) => {
      acc += e.question.trim() + "\n" + e.answer.trim() + "\n\n";
      return acc;
    }, '');
  }

  getArrayOfQuestionAnswers(messages: string) {
    return messages.split('\n\n').map(message => {
      const [question, answer] = message.split('\n');
      return { question, answer };
    }).filter(e => e.question && e.answer);
  }

  generateStopArray(stop: string) {
    return [...new Set(stop.split(/\s+/))];
  }

  replaceName(param: string) {
    return param.replace('_', ' ').replace(/\b\w/g, c => c.toUpperCase())
  }

  closeModal() {
    this.windowRef.close();
  }

  toggleExpertMode() {
    this.isExpertMode = !this.isExpertMode;
  }

  getExpertPramsLabels() {
    return Object.entries(this.options).filter(([key, option]: any) => option.expert).map(([key]: any) => key)
  }

  autoResize(event: any) {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight + 5 }px`;
  }

  onChangePreset(presetName: string) {
    if (!this.currentPreset || this.currentPreset === presetName) {
      return;
    }
    this.openPresetConfirmModal(presetName);
  }

  openPresetConfirmModal(presetName: string) {
    this.windowService.open(ConfirmModalComponent, {
      title: `Are you sure you want change preset?`,
      buttons: buttonsConfig,
      context: {
        textContent: `This action will permanently change all fields`,
        actionButtonText: "Set Preset",
        actionCallback: () => this.setPreset(presetName),
        cancelCallback: () => {this.tempPreset = this.currentPreset}
      },
      windowClass: "confirm-modal"
    });
  }

  setPreset(presetName: string) {
    this.currentPreset = presetName;
    this.tempPreset = presetName;
    let character: Character = { aiModel: '', id: '', name: '', userId: 0 };
    if (this.currentPreset === defaultCharacterPreset) {
      this.setUpCharacterData(character);
    } else {
      this.setUpCharacterData(characterPresets.find(preset => preset.name === presetName)|| character);
    }
  }
}
