import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { VpsManagementComponent } from './pages/vps-management/vps-management.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { PageLabelComponent } from './components/page-label/page-label.component';
import { SidebarButtonComponent } from './components/sidebar-button/sidebar-button.component';
import { CardComponent } from './components/card/card.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  NbAccordionModule,
  NbActionsModule,
  NbButtonModule,
  NbCardModule,
  NbContextMenuModule,
  NbGlobalPhysicalPosition,
  NbIconModule,
  NbLayoutModule,
  NbMenuModule,
  NbProgressBarModule,
  NbSelectModule,
  NbTabsetModule,
  NbTagModule,
  NbThemeModule,
  NbToastrModule,
  NbTreeGridModule,
  NbUserModule,
  NbWindowModule,
  NbInputModule,
  NbFormFieldModule,
  NbSpinnerModule,
  NbAlertModule,
  NbCheckboxModule,
  NbAutocompleteModule,
  NbChatModule,
  NbRadioModule, NbTooltipModule,
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { VideoComponent } from 'pages/video/video.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { VideoLengthPipe } from './pipes/video-length.pipe';
import { VideoSizePipe } from './pipes/video-size.pipe';
import { VideoStatusComponent } from './components/video-status/video-status.component';
import { CardPaginationComponent } from './components/card-pagination/card-pagination.component';
import { NewVideoModalComponent } from './components/new-video-modal/new-video-modal.component';
import { ModalActionButtonsComponent } from './components/modal-action-buttons/modal-action-buttons.component';
import { FileDndDirective } from './directives/upload-dnd.directive';
import { ContaboVpsTableComponent } from './components/contabo-vps-table/contabo-vps-table.component';
import { StoreModule } from '@ngrx/store';
import { resourceFeature } from 'store/reducers/videos.reducers';
import { EffectsModule } from '@ngrx/effects';
import { VideosEffects } from 'store/effects/video.effects';
import { TransformTableGridPipe } from './pipes/transform-table-grid.pipe';
import { PaginatePipe } from './pipes/paginate.pipe';
import { SortNbTablePipe } from './pipes/sort-nb-table.pipe';
import { AppState } from 'models/app.state';
import { userFeature } from 'store/reducers/user.reducers';
import { AuthInterceptor } from './http-interceptor/auth-interceptor';
import { PlaylistComponent } from './pages/playlist/playlist.component';
import { PlaylistCardComponent } from './components/playlist-card/playlist-card.component';
import { PlaylistModalComponent } from './components/playlist-modal/playlist-modal.component';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { playlistFeature } from './store/reducers/playlist.reducers';
import { PlaylistVideoLengthPipe } from './pipes/playlist-video-length.pipe';
import { AutosizeModule } from 'ngx-autosize';
import { CDK_DRAG_CONFIG, DragDropModule } from '@angular/cdk/drag-drop';
import { StreamComponent } from './pages/stream/stream.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { StreamCardComponent } from './components/stream-card/stream-card.component';
import { NewStreamModalComponent } from './components/new-stream-modal/new-stream-modal.component';
import { streamFeature } from './store/reducers/stream.reducers';
import { DashboardCardComponent } from './components/dashboard-card/dashboard-card.component';
import { dashboardFeature } from 'store/reducers/dashboard.reducers';
import { DashboardEffects } from 'store/effects/dashboard.effects';
import { NewVpsModalComponent } from './components/new-vps-modal/new-vps-modal.component';
import { EditVideoModalComponent } from './components/edit-video-modal/edit-video-modal.component';
import { buildSpecificModules } from 'build-specifics';
import { FilterNbTablePipe } from './pipes/filter-nb-table.pipe';
import { VideoSortPipe } from './pipes/video-default-sort.pipe';
import { FilterDataPipe } from './pipes/filter-data.pipe';
import { VpsDetailsModalComponent } from './components/vps-details-modal/vps-details-modal.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { VideoTableComponent } from './components/video-table/video-table.component';
import { CharacterComponent } from 'pages/character/character.component';
import { CharacterTableComponent } from './components/character-table/character-table.component';
import { CharactersEffect } from './store/effects/characters.effects';
import { characterFeature } from './store/reducers/characters.reducers';
import { CharacterSortPipe } from './pipes/character-sort.pipe';
import { NewCharacterModalComponent } from './components/new-character-modal/new-character-modal.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { SearchSelectComponent } from './components/search-select/search-select.component';
import { StreamCharacterModalComponent } from './components/stream-character-modal/stream-character-modal.component';
import { ChatModalComponent } from './components/chat-modal/chat-modal.component';
import { AuthorizationCodeComponent } from './components/authorization-code/authorization-code.component';
import { WarningModalComponent } from './components/warning-modal/warning-modal.component';
import { VpsLogsComponent } from './components/vps-logs/vps-logs.component';
import { VideoListTableComponent } from './components/video-list-table/video-list-table.component';
import {
  NewPlaylistVideosModalComponent
} from './components/new-playlist-videos-modal/new-playlist-videos-modal.component';
import {
  PlaylistCreationVideoTableComponent
} from './components/playlist-creation-video-table/playlist-creation-video-table.component';
import { AdminSettingsComponent } from './pages/adminSetting/admin-settings.component';
import {
  TriggerWixCompareModalComponent
} from './components/trigger-wix-compare-modal/trigger-wix-compare-modal.component';
import { OrientalRectangleComponent } from './information-components/oriental-rectangle/oriental-rectangle.component';
import { StreamCardPlaceholderComponent } from './components/stream-card-placeholder/stream-card-placeholder.component';
import { GiveSuggestionModalComponent } from './components/give-suggestion-modal/give-suggestion-modal.component';
import { SuggestionsComponent } from './pages/suggestions/suggestions.component';
import { InformationRectangleComponent } from './base-components/information-rectangle/information-rectangle.component';
import { ChatRectangleComponent } from './information-components/chat-rectangle/chat-rectangle.component';



@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    NbThemeModule.forRoot({name: 'default'}),
    NbLayoutModule,
    NbEvaIconsModule,
    NbTreeGridModule,
    NbCardModule,
    NbMenuModule.forRoot(),
    NbContextMenuModule,
    NbToastrModule.forRoot({position: NbGlobalPhysicalPosition.TOP_RIGHT, status: 'basic'}),
    NbActionsModule,
    NbIconModule,
    NbUserModule,
    NbTagModule,
    NbButtonModule,
    NbSelectModule,
    NbWindowModule.forChild(),
    NbTabsetModule,
    FormsModule,
    NbProgressBarModule,
    NbAccordionModule,
    NbInputModule,
    NbFormFieldModule,
    AutosizeModule,
    NbSpinnerModule,
    StoreModule.forRoot<AppState>(),
    StoreModule.forFeature(resourceFeature),
    StoreModule.forFeature(playlistFeature),
    StoreModule.forFeature(userFeature),
    StoreModule.forFeature(streamFeature),
    StoreModule.forFeature(dashboardFeature),
    StoreModule.forFeature(characterFeature),
    EffectsModule.forRoot([VideosEffects, DashboardEffects, CharactersEffect]),
    DragDropModule,
    ...buildSpecificModules,
    NbAlertModule,
    ClipboardModule,
    NbCheckboxModule,
    NgxSliderModule,
    NbAutocompleteModule,
    NbChatModule,
    NbRadioModule,
    NbTooltipModule,
  ],
  declarations: [
    AppComponent,
    TopBarComponent,
    SidebarComponent,
    VpsManagementComponent,
    PageLabelComponent,
    SidebarButtonComponent,
    CardComponent,
    VideoComponent,
    VideoLengthPipe,
    PlaylistVideoLengthPipe,
    VideoSizePipe,
    VideoSortPipe,
    VideoStatusComponent,
    CardPaginationComponent,
    NewVideoModalComponent,
    ConfirmModalComponent,
    ModalActionButtonsComponent,
    FileDndDirective,
    NewVpsModalComponent,
    ContaboVpsTableComponent,
    TransformTableGridPipe,
    PaginatePipe,
    SortNbTablePipe,
    FilterDataPipe,
    PlaylistComponent,
    PlaylistCardComponent,
    PlaylistModalComponent,
    StreamComponent,
    DashboardComponent,
    StreamCardComponent,
    NewStreamModalComponent,
    DashboardCardComponent,
    EditVideoModalComponent,
    FilterNbTablePipe,
    VpsDetailsModalComponent,
    VideoTableComponent,
    CharacterComponent,
    CharacterTableComponent,
    CharacterSortPipe,
    NewCharacterModalComponent,
    SearchSelectComponent,
    StreamCharacterModalComponent,
    ChatModalComponent,
    AuthorizationCodeComponent,
    WarningModalComponent,
    VpsLogsComponent,
    VideoListTableComponent,
    PlaylistCreationVideoTableComponent,
    NewPlaylistVideosModalComponent,
    AdminSettingsComponent,
    TriggerWixCompareModalComponent,
    OrientalRectangleComponent,
    StreamCardPlaceholderComponent,
    GiveSuggestionModalComponent,
    SuggestionsComponent,
    InformationRectangleComponent,
    ChatRectangleComponent
  ],
  bootstrap: [
    AppComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  },{ provide: LocationStrategy, useClass: PathLocationStrategy }, { provide: CDK_DRAG_CONFIG, useValue: {
      dragStartThreshold: 0,
      pointerDirectionChangeThreshold: 5,
      zIndex: 10000
    } }]
})
export class AppModule {
}
