import { Injectable } from '@angular/core';
import { AxiosResponse } from 'axios';
import { environment } from '../../environments/environment';
import { AxiosInstance } from './axios-instance.service';
import { from, Observable } from 'rxjs';
import { ConfigOption } from '../consts/admin-setting';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  private readonly apiUrl = environment.apiUrl + "/admin";
  private readonly axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = new AxiosInstance(this.apiUrl, 'Admin Service');
  }

  updateAllVps(): Promise<AxiosResponse<any>> {
    return this.axiosInstance.post('/update-all-vps');
  }

  getAllOptions$(): Observable<ConfigOption[]> {
    return from(this.axiosInstance.get('/config').then(res => res.data));
  }
}
