import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  NbMenuItem, NbMenuService, NbPosition,
  NbSortDirection,
  NbSortRequest,
  NbToastrService, NbWindowRef,
  NbWindowService
} from '@nebular/theme';
import { select, Store } from '@ngrx/store';
import { ConfirmModalComponent } from 'components/confirm-modal/confirm-modal.component';
import { DataState } from 'enum/data-state.enum';
import { PageChange } from 'models/events';
import { ResourceEntry } from 'models/resource';
import {
  filter, map,
  Observable, Subscription,
  take
} from 'rxjs';
import { VideoPageActions, VideoUploadActions } from 'store/actions/videos.actions';
import {
  getResourcePageError,
  resourceFeature
} from 'store/reducers/videos.reducers';
import { Stream } from 'models/stream';
import { streamFeature } from 'store/reducers/stream.reducers';
import { StreamPlatform, StreamStatus } from 'enum/streams.enum';
import { PlaylistVideo } from 'models/playlist';
import { buttonsConfig, User } from '../../models';
import { showError, showWarning } from 'helpers/nb';
import { LSKeys } from 'enum/local-storage-key.enum';
import { ResourceService } from '../../services/resource.service';
import { UploadVideoSource } from '../../enum/upload-source.enum';
import { Actions, ofType } from '@ngrx/effects';
import { WarningModalComponent } from '../warning-modal/warning-modal.component';
import { AuthorizationCodeComponent } from '../authorization-code/authorization-code.component';
import { getSourcePlatformRoute, getStatusFromProgress } from '../../helpers/videoStatus.helper';
import { VideoStatusCode } from '../../enum/video-status.code';
import { UploadStatus } from '../../enum/upload-status.enum';
import { PlatformAccessStatuses } from '../../consts/platform-access-statuses';

@Component({
  selector: 'app-video-table',
  templateUrl: './video-table.component.html',
  styleUrls: ['./video-table.component.scss']
})
export class VideoTableComponent implements OnInit, OnDestroy {
  @Input('resources$') resources$: Observable<ResourceEntry[]>;
  @Input('selectedRows') selectedRows: Set<number>;
  @Input('user') user: User;
  tableColumnLabels = {
    checkbox: 'Checkbox',
    series: 'Series',
    name: 'Video Name',
    length: 'Length',
    uploadStatusCode: 'Video Status',
    size: 'Used Space',
    actions: 'Actions',
  } as const;
  tableColumnNames = Object.fromEntries(Object.entries(this.tableColumnLabels).map(([k, v]) => [v, k]));
  readonly allTableColumns = Object.keys(this.tableColumnLabels);

  sortColumn: string;
  sortRequest: NbSortRequest | null;
  sortDirection: NbSortDirection = NbSortDirection.NONE;

  error$: Observable<string | null>;
  pageState$: Observable<DataState>;
  countOfUploadingVideos: number = 0;
  vpsStatistics: { usedSpace: number, count: number, length: number } = {
    length: 0,
    usedSpace: 0,
    count: 0
  };
  streams$: Observable<Stream[]>;
  pageSize: number;
  pageNumber: number = 1;
  LSPageSizeKey = LSKeys.videoPageSize;
  isSelectedAll: boolean = false;

  videoTableContextItems: NbMenuItem[] = [
    {title: 'Delete', data: this.deleteResource.bind(this)}
  ];
  errorUploadVideoTableContextItems: NbMenuItem[] = [
    {title: 'Retry upload', data: this.retryUpload.bind(this)},
  ];
  contextItemsPosition: NbPosition = NbPosition.BOTTOM;
  contextMenuTag: string = 'my-video-table-context-menu';
  contextMenuActiveRow: any;
  menuServiceSubscription: Subscription;
  subscription: Subscription = new Subscription();
  mappedResources$: Observable<ResourceEntry[]>;

  constructor(
    private windowService: NbWindowService,
    private store: Store,
    private toastrService: NbToastrService,
    private nbMenuService: NbMenuService,
    private resourceService: ResourceService,
    private actions$: Actions,
  ) {
    this.pageState$ = this.store.pipe(select(resourceFeature.selectPageState));
    this.error$ = this.store.pipe(select(getResourcePageError));
    this.store.pipe(select(resourceFeature.getResourcePageStatistics)).subscribe((data: any) => {
      this.vpsStatistics = data;
    });
    this.streams$ = this.store.pipe(select(streamFeature.selectStreamState));
    this.menuServiceSubscription = this.nbMenuService.onItemClick()
      .pipe(
        filter(({tag}) => tag === this.contextMenuTag),
        map(({item: {data}}) => data),
      )
      .subscribe(callback => {
        if (callback) callback(this.contextMenuActiveRow);
      });
  }

  ngOnInit(): void {
    this.mappedResources$ = this.resources$.pipe(
      map((resources) => resources
        .map((resource) => ({ ...resource, uploadStatusCode: this.getVideoStatusCode(resource) }))
      )
    )
    let pageSize = localStorage.getItem(this.LSPageSizeKey) ?? '10';
    this.pageSize = parseInt(pageSize);

    this.subscription.add(this.store.pipe(select(resourceFeature.getProcessedVideosCount))
      .subscribe((videoInUpload) => {
        this.countOfUploadingVideos = videoInUpload;
      })
    );
    this.subscription.add(this.mappedResources$.subscribe(() => this.isSelectedAll = false));
  }

  retryUpload(resource: ResourceEntry) {
    this.store.dispatch(VideoUploadActions.startPolling());
    this.store.dispatch(VideoUploadActions.uploadUrlVideo({
        payload: { entry: resource, sourceId: resource.sourceKey },
        source: getSourcePlatformRoute(resource)
    }));
  }

  getVideoStatusCode(resource: ResourceEntry) {
    if (UploadStatus.PROCESSING === getStatusFromProgress(resource.uploadStatus) || resource.statusCode === VideoStatusCode.PENDING_UPLOADING || resource.statusCode === VideoStatusCode.PREPARING) {
      return 1;
    } else if (UploadStatus.UPLOADING === getStatusFromProgress(resource.uploadStatus) || UploadStatus.UPLOADING === getStatusFromProgress(resource.uploadStatus)) {
      return 2;
    } else if (UploadStatus.READY === getStatusFromProgress(resource.uploadStatus) && resource.statusCode === VideoStatusCode.READY_FOR_USE) {
      return 3;
    } else if (UploadStatus.READY === getStatusFromProgress(resource.uploadStatus) && resource.statusCode === VideoStatusCode.ERROR_CODEC) {
      return 4;
    } else if (UploadStatus.ERROR === getStatusFromProgress(resource.uploadStatus) && resource.statusCode === VideoStatusCode.ERROR_ACCESS) {
      return 6;
    } else if (UploadStatus.ERROR === getStatusFromProgress(resource.uploadStatus) || resource.statusCode === VideoStatusCode.ERROR_UPLOADING) {
      return 5;
    } else {
      return 0;
    }
  }

  deleteResource(resource: ResourceEntry) {
    this.streams$.pipe(take(1)).subscribe((allStreams) => {
      const videoIsUsed: boolean = this.isVideoUsedByStreams(resource, allStreams);
      if (videoIsUsed) {
        showWarning(this.toastrService, 'To delete this video, you must first stop the stream that is currently using it. The video cannot be removed while it is still being utilized by the stream.')
        return;
      }

      this.windowService.open(ConfirmModalComponent, {
        title: `Are You Sure You Want to Delete this Video?`,
        buttons: buttonsConfig,
        context: {
          textContent: `This action will permanently delete the video "${resource.name}" and it cannot be undone.`,
          actionCallback: () => this.store.dispatch(VideoPageActions.removeVideo({id: resource.id})),
        },
      });
    })
  }

  updateSort(sortRequest: NbSortRequest): void {
    this.sortColumn = sortRequest.column;
    this.sortRequest = sortRequest;
    this.sortDirection = sortRequest.direction;
  }

  getSortDirection(column: string): NbSortDirection {
    if (this.sortColumn === column) {
      return this.sortDirection;
    }
    return NbSortDirection.NONE;
  }

  onPageChange(pageEvent: PageChange) {
    this.pageSize = pageEvent.pageSize;
    this.pageNumber = pageEvent.currentPage;
  }

  ngOnDestroy() {
    this.menuServiceSubscription.unsubscribe();
    this.subscription.unsubscribe();
  }

  private isVideoUsedByStreams(video: ResourceEntry, allStreams: Stream[]) {
    const playlistVideos: PlaylistVideo[] = allStreams
      .filter(stream => stream.status === StreamStatus.Online)
      .map((activeStream: Stream) => activeStream.playlist?.playlistVideos || [])
      .flat();

    return playlistVideos.some(item => item.video.id === video.id);
  }

  toggleAllSelection(event: Event) {
    this.mappedResources$.pipe(take(1)).subscribe((resources) => {
      this.isSelectedAll = !this.isSelectedAll;
      if (this.isSelectedAll) {
        resources.forEach(resource => this.selectedRows.add(resource.id));
      } else {
        this.selectedRows.clear();
      }
    });
  }

  checkIsAllShouldBeSelected() {
    this.mappedResources$.pipe(take(1)).subscribe((resources) => {
      this.isSelectedAll = !!resources.length && resources.length === this.selectedRows.size;
    });
  }

  toggleSelection(row: ResourceEntry) {
    if (this.selectedRows.has(row.id)) {
      this.selectedRows.delete(row.id);
    } else {
      this.selectedRows.add(row.id);
    }

    this.checkIsAllShouldBeSelected();
  }

  isSelected(row: ResourceEntry): boolean {
    return this.selectedRows.has(row.id);
  }

  isResourceAreBroken(resource: ResourceEntry) {
    return !resource || (resource.uploadStatus === -1 && resource.statusCode != 1);
  }

  getVideoTableContextItems(row: ResourceEntry) {
    return [
      ...(this.isResourceAreBroken(row) && row.sourceKey ? this.errorUploadVideoTableContextItems : []),
     ...this.videoTableContextItems,
    ];
  }

  openCheckAccessModal() {
    this.store.dispatch(VideoUploadActions.checkServiceAccess({ platformName: UploadVideoSource.YOUTUBE }));
    this.actions$.pipe(
      ofType(VideoUploadActions.authorizationRequired),
      take(1)
    )
      .subscribe(({ authCode, error, status = 1 }) => {
        if (error) {
          console.error(error);
          showError(this.toastrService, "Failed to check access. Error: " + (typeof error === 'string' ? error : error.message));
          return;
        }
        if (authCode) {
          showWarning(this.toastrService, `Youtube access required. ${PlatformAccessStatuses[status]}`)
          this.windowService.open(WarningModalComponent, {
            title: `Youtube Access Required`,
            buttons: buttonsConfig,
            context: {
              actionButtonText: "retry",
              customComponent: AuthorizationCodeComponent,
              actionCallback: () => this.openCheckAccessModal(),
              customComponentParams: {
                platform: StreamPlatform.YouTube,
                authCode
              }
            },
          });
        } else {
          this.store.dispatch(VideoUploadActions.updateAccessRequiredVideos());
        }
      });
  }
}
