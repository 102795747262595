import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { Character, ChatMessage } from '../../models';
import { CharacterActions, CharacterChatActions } from '../actions/characters.actions';
import { DataState } from '../../enum/data-state.enum';

export interface CharacterState {
  characters: Character[] | [];
  pageState: DataState;
  error: string | null;
  chatMessages: ChatMessage[] | [];
  tokens: Number;
}

const initialCharacterState: CharacterState = {
  characters: [],
  pageState: DataState.LOADED,
  error: null,
  chatMessages: [],
  tokens: 0,
}

export const characterFeature = createFeature({
  name: 'character',
  reducer: createReducer(
    initialCharacterState,
    on(CharacterActions.getCharactersList, (state) => {
      return { ...state, pageState: DataState.LOADING };
    }),
    on(CharacterActions.getCharactersListSuccess, (state, { characters }) => {
      return { ...state, pageState: DataState.LOADED, characters };
    }),
    on(CharacterActions.addCharacter, (state, { character }) => {
      return { ...state, pageState: DataState.LOADED, characters: [...state.characters, character ] };
    }),
    on(CharacterActions.deleteCharacter, (state, { id }) => {
      const characters = state.characters.filter((character) => id !== character.id);
      return { ...state, pageState: DataState.LOADED, characters };
    }),
    on(CharacterActions.updateCharacter, (state, { character }) => {
      const characters = state.characters.map((pl) => pl.id === character.id ? {...pl, ...character} : pl);
      return { ...state, pageState: DataState.LOADED, characters };
    }),
    on(CharacterActions.getCharactersListFailure, (state, { error }) => {
      return { ...state, pageState: DataState.ERROR, error };
    }),
    on(CharacterActions.deleteCharacters, (state, { ids }) => {
      const characters = state.characters.filter((character) => !ids.includes(character.id));
      return { ...state, pageState: DataState.LOADED, characters };
    }),
    on(CharacterChatActions.addMessage, (state, { message, tokens = 0 }) => {
      return { ...state, chatMessages: [...state.chatMessages, message ], tokens: tokens || 0 };
    }),
    on(CharacterChatActions.sendMessage, (state, { message }) => {
      return { ...state, chatMessages: [...state.chatMessages, message ] };
    }),
    on(CharacterChatActions.clearMessages, (state) => {
      return { ...state, chatMessages: [], tokens: 0 };
    }),
  ),
  extraSelectors: ({ selectCharacterState }) => ({
    getAllCharacters: createSelector(
      selectCharacterState,
      (state: CharacterState) => state.characters
    ),
    getCharactersPageError: createSelector(
      selectCharacterState,
      (state: CharacterState) => state.error
    ),
    selectPageState: createSelector(
      selectCharacterState,
      (state: CharacterState) => state.pageState
    ),
    getChatMessages: createSelector(
      selectCharacterState,
      (state: CharacterState) => state.chatMessages
    ),
    getTokens: createSelector(
      selectCharacterState,
      (state: CharacterState) => state.tokens
    ),
  })
});
