import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { filter, map, pairwise } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { userFeature } from '../store/reducers/user.reducers';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class ChatbotLogs {
  private baseUrl = environment.host.replace("http", "ws");
  private maxRetries = 3;
  private retryCount = 0;
  private socket: WebSocket | null = null;
  private keepAliveInterval: any;

  constructor(
    private store: Store,
    private logService: LogService
  ) { }

  public tryStartGettingLogs() {
    this.store.pipe(select(userFeature.selectUserState))
      .pipe(
        map((user: any) => user?.assignedVps
          && user.assignedVps.isVpsAccessible
          && user.assignedVps.isVpsUp
          && user.assignedVps.ip
        ),
        pairwise(),
        filter(([vpsIp1, vpsIp2]) => vpsIp1 !== vpsIp2),
        map(([_, vpsIp]) => vpsIp)
      )
      .subscribe((vpsIp: any) => {
        if (vpsIp) {
          this.startSocket(vpsIp);
        } else {
          this.stopSocket();
        }
      });
  }

  private startSocket(vpsIp: string) {
    const vpsSocketUrl = `${this.baseUrl}/logs?vpsIp=${vpsIp}`;
    this.socket = new WebSocket(vpsSocketUrl);

    this.socket.onopen = (event) => {
      console.log('WebSocket is open now.');
      this.retryCount = 0;

      this.keepAliveInterval = setInterval(() => {
        this.socket?.send(JSON.stringify({ type: 'ping' }));
      }, 30000);
    };

    this.socket.onmessage = (event) => {
      const logMessage = event.data;
      if (logMessage) {
        let message = JSON.parse(logMessage);

        message = { ...message, log: message.log ? JSON.parse(message.log): undefined }
        this.logService.logToConsole(message);
      }
    };

    this.socket.onclose = (event) => {
      console.log('WebSocket connection closed. Reconnecting... ', event);
      clearInterval(this.keepAliveInterval);
      this.retryCount++;
      if (this.retryCount <= this.maxRetries) {
        console.log(`Attempting to reconnect... (Retry ${this.retryCount}/${this.maxRetries})`);
        setTimeout(() => this.startSocket(vpsIp), 2000)
      } else {
        console.log('Max retry limit reached. Stopping attempts.');
        this.retryCount = 0;
      }
    };

    this.socket.onerror = (event) => {
      console.log('WebSocket error:', event);
      this.socket?.close();
    };
  }

  public stopSocket() {
    if (this.socket) {
      clearInterval(this.keepAliveInterval);
      this.socket.close();
      console.log('WebSocket connection stopped.');
    }
  }
}
