import { Injectable } from '@angular/core';
import { FileCreation, PlaylistUploadCreation, PollingPayload, ResourceEntry, ResourceResponse, ResourceUploadPayload } from 'models/resource';
import { Observable, from } from 'rxjs';
import { environment } from '../../environments/environment';
import { Vps } from 'models/vps';
import { UploadVideoSource } from '../enum/upload-source.enum';
import { AxiosInstance } from './axios-instance.service';
import { splitFileName } from 'helpers/resource.helper';
import { HttpEvent, HttpEventType, HttpHeaders, HttpResponse } from '@angular/common/http';
import { AxiosProgressEvent } from 'axios';

@Injectable({ providedIn: 'root' })
export class ResourceService {
  private readonly apiUrl = `${environment.apiUrl}/resource`;
  private readonly axiosInstance: AxiosInstance;
  private readonly vpsAxiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = new AxiosInstance(this.apiUrl, "Resource service");;
    this.vpsAxiosInstance = new AxiosInstance(environment.host, "Resource VPS service");
  }

  getResources$(): Observable<ResourceResponse<ResourceEntry>> {
    return from(this.axiosInstance.get<ResourceResponse<ResourceEntry>>('/get-all').then(res => res.data));
  }

  createEntities(source: UploadVideoSource, fileCreation: FileCreation[]): Promise<ResourceEntry[]> {
    return this.axiosInstance.post(`${this.apiUrl}/${source}`, fileCreation).then(res => res.data);
  }

  updateEntity(updatedResource: ResourceEntry): Promise<ResourceEntry> {
    return this.axiosInstance.patch<ResourceEntry>(this.apiUrl, updatedResource).then(res => res.data);
  }

  deleteResource$(id: number): Observable<boolean> {
    return from(this.axiosInstance.delete<boolean>(`${this.apiUrl}/delete/${id}`).then(res => res.data));
  }

  deleteResources$(ids: number[]): Observable<boolean> {
    return from(this.axiosInstance.delete<boolean>(`${this.apiUrl}/delete`, { data: { ids }}).then(res => res.data));
  }

  deleteAll$(userEmail: string): Observable<boolean> {
    return from(this.axiosInstance.delete<boolean>(`${this.apiUrl}/delete/all/${userEmail}`).then(res => res.data));
  }

  setResourceErrorUpload(id: number, error?: number): Promise<ResourceEntry[]> {
    const errorQuery = error ? `?error=${error}` : '';
    return this.axiosInstance.post(`${this.apiUrl}/error/${id}${errorQuery}`).then(res => res.data);
  }

  setResourceUpload(id: number): Promise<ResourceEntry[]> {
    return this.axiosInstance.post(`${this.apiUrl}/upload/${id}`).then(res => res.data);
  }

  setResourceAccessErrorUpload(id: number): Promise<ResourceEntry[]> {
    return this.axiosInstance.put(`${this.apiUrl}/access/error/${id}`).then(res => res.data);
  }

  uploadByUrl$(vps: Vps | undefined, { entry, sourceId }: ResourceUploadPayload, source: UploadVideoSource): Observable<any> {
    if (!vps) {
      throw new Error("Could not download YouTube video");
    }
    const payload = { sourceId, id: entry.id, name: entry.physicalName, thumbnail: entry.thumbnail };
    return from(
      this.vpsAxiosInstance.post('/proxy/upload/' + source, payload, this.vpsAxiosInstance.defaultProxyConfig(vps.ip))
        .then(res => res.data)
    );
  }

  uploadFile$(vps: Vps | undefined, { entry, file }: ResourceUploadPayload): Observable<HttpEvent<ResourceEntry>> {
    if (!vps) {
      throw new Error("Could not upload local file");
    }

    const formData: FormData = new FormData();
    const [_, extension] = splitFileName(file!);
    const physicalNameWithExt = `${entry.physicalName}.${extension}`;
    formData.append('file', file!, physicalNameWithExt);
    formData.append('id', entry.id.toString());

    const controller = new AbortController();
    const signal = controller.signal;

    return new Observable<HttpEvent<ResourceEntry>>((observer) => {
      this.vpsAxiosInstance
        .post<ResourceEntry>("/proxy/upload/local", formData, {
          ...this.vpsAxiosInstance.defaultProxyConfig(vps.ip),
          signal,
          onUploadProgress: (progressEvent) => {
            observer.next({
              type: HttpEventType.UploadProgress,
              loaded: progressEvent.loaded,
              total: progressEvent.total,
            });
          },
        })
        .then((response) => {
          const httpResponse = new HttpResponse<ResourceEntry>({
            status: response.status,
            body: response.data,
            headers: new HttpHeaders(),
            statusText: response.statusText || '',
            url: response.config.url || '',
          });
          observer.next(httpResponse);
          observer.complete();
        })
        .catch((error) => {
          if (error.name === 'AbortError') {
          } else {
            observer.error(error);
          }
        });

      return () => {
        controller.abort();
      };
    });
  }

  fetchYouTubeProgress$(vps: Vps | undefined): Observable<PollingPayload> {
    if (!vps) {
      throw new Error("Could not get YouTube upload progress!");
    }
    return from(
      this.vpsAxiosInstance
        .get<PollingPayload>('/proxy/upload/progress', this.vpsAxiosInstance.defaultProxyConfig(vps.ip))
        .then(res => res.data)
    );
  }

  checkServiceAccess(vps: Vps | undefined, service: string): Observable<string> {
    if (!vps) {
      throw new Error("Could not get YouTube upload access!");
    }
    return from(
      this.vpsAxiosInstance.get<string>(`/proxy/access/${service}`, this.vpsAxiosInstance.defaultProxyConfig(vps.ip))
        .then(res => res.data)
    );
  }

  getPlaylistVideos(source: UploadVideoSource, playlistId: string): Promise<PlaylistUploadCreation> {
    return this.axiosInstance.get(`${this.apiUrl}/${source}/playlist?playlistId=${playlistId}`).then(res => res.data);
  }
}
