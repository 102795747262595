<table
  nbSort
  class="video-list-table"
  [nbTreeGrid]="(getAvailableVideosToSelect(searchableResources) | transformTableGrid ) || []"
  [trackBy]="trackBy"
>
  <tr nbTreeGridHeaderRow *nbTreeGridHeaderRowDef="resourceListTableColumns"></tr>
  <tr nbTreeGridRow *nbTreeGridRowDef="let row; columns: resourceListTableColumns" [clickToToggle]="false"></tr>

  <ng-container [nbTreeGridColumnDef]="resourceListColumnNames.name">
    <th
      style="width: 80%;"
      nbTreeGridHeaderCell
      *nbTreeGridHeaderCellDef
    >{{ resourceListColumnNames.name }}</th>
    <td
      nbTreeGridCell
      *nbTreeGridCellDef="let row"
    >
      <div class="video-content">
        <div class="table-thumbnail" [ngClass]="{'table-thumbnail-vertical': row.data.isVerticalOrientation}">
          <img [id]="row?.data?.id" [src]="row.data.thumbnail || 'assets/DefaultVideoThumbnail.jpg'" alt="Thumbnail">
        </div>
        <span class="video-name">
          {{ row.data.name }}
        </span>
      </div>
    </td>
  </ng-container>

  <ng-container [nbTreeGridColumnDef]="resourceListColumnNames.actions">
    <th
      style="width: 20%;"
      nbTreeGridHeaderCell
      *nbTreeGridHeaderCellDef
    >
      <button
        nbButton
        outline
        status="primary"
        style="background-color: transparent;"
        (mouseup)="addAllVideosToPlaylist()"
      >
        Add All
      </button>
    </th>
    <td
      nbTreeGridCell
      *nbTreeGridCellDef="let row"
    >
      <div class="d-flex justify-content-center align-items-center">
        <button
          nbButton
          outline
          status="primary"
          style="background-color: transparent;"
          (mouseup)="addVideoToPlaylist(editablePlaylist.playlistVideos.length, row.data)"
        >
          Add
        </button>
      </div>
    </td>
  </ng-container>
</table>
