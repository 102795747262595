import { Injectable } from '@angular/core';
import { AxiosResponse } from 'axios';
import { environment } from '../../environments/environment';
import { Character, ChatMessage, DraftCharacter } from '../models';
import { AxiosInstance } from './axios-instance.service';
import { from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CharacterService {

  private readonly apiUrl = `${environment.apiUrl}/character`;
  private readonly axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = new AxiosInstance(this.apiUrl, "Character service");
  }

  getAll(userUuid: string): Promise<AxiosResponse<Character[]>> {
    return this.axiosInstance.get(`/user/${userUuid}`);
  }

  getAll$(userUuid: string | undefined): Observable<Character[]> {
    return from(this.axiosInstance.get(`/user/${userUuid}`).then(response => response.data));
  }

  createNewCharacter(newCharacterData: DraftCharacter) {
    return this.axiosInstance.post('/', newCharacterData);
  }

  updateCharacter(updatedCharacterData: Character) {
    return this.axiosInstance.put('/', updatedCharacterData);
  }

  deleteCharacter(characterId: string): Promise<AxiosResponse<Character>> {
    return this.axiosInstance.delete(`/${characterId}`).then(response => response.data);
  }

  deleteCharacters(ids: string[]): Observable<boolean> {
    return from(this.axiosInstance.delete('/list', { data: { ids } }).then(response => response.data));
  }

  sendMessage$(
    messages: ChatMessage[],
    characterId: string,
    streamId: number | undefined,
    isChatLogsEnabled: boolean = false
  ): Observable<any> {
    return from(this.axiosInstance
      .post(`/${characterId}/messages`, messages,{ params: { streamId, isChatLogsEnabled }}
    )
      .then(response => response.data));
  }
}
