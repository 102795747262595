<div>
  <div style="max-height: 600px; overflow: scroll; overflow-x: hidden;">
    <table
      nbSort
      class="my-characters-table"
      (sort)="updateSort($event)"
      [nbTreeGrid]="(characters$ | async | paginate: pageSize:pageNumber | transformTableGrid | sortNbTable: sortRequest) || []"
    >
      <tr nbTreeGridHeaderRow *nbTreeGridHeaderRowDef="allTableColumns"></tr>
      <tr nbTreeGridRow *nbTreeGridRowDef="let row; columns: allTableColumns" [clickToToggle]="false"></tr>

      <ng-container [nbTreeGridColumnDef]="tableColumnNames[tableColumnLabels.checkbox]">
        <th nbTreeGridHeaderCell *nbTreeGridHeaderCellDef class="my-characters-table-peripheral p-0">
          <div class="my-characters-table-checkbox">
            <nb-checkbox
              (change)="toggleAllSelection($event)"
              [checked]="isSelectedAll"
              class="my-characters-table-checkbox"
            />
          </div>
        </th>
        <td nbTreeGridCell *nbTreeGridCellDef="let row" class="my-characters-table-peripheral p-0">
          <div class="my-characters-table-checkbox">
            <nb-checkbox type="checkbox" [checked]="isSelected(row.data)" (change)="toggleSelection(row.data)"/>
          </div>
        </td>
      </ng-container>

      <ng-container [nbTreeGridColumnDef]="tableColumnNames[tableColumnLabels.series]">
        <th
          class="my-characters-table-peripheral p-0"
          nbTreeGridHeaderCell
          *nbTreeGridHeaderCellDef
        ></th>
        <td
          class="my-characters-table-peripheral p-0"
          nbTreeGridCell
          *nbTreeGridCellDef="let i = index"
        >
          {{ (pageSize * (pageNumber - 1)) + (i + 1) }}
        </td>
      </ng-container>

      <ng-container [nbTreeGridColumnDef]="tableColumnNames[tableColumnLabels.name]">
        <th
          style="width: 30%;"
          nbTreeGridHeaderCell
          [nbSortHeader]="getSortDirection(tableColumnLabels.name)"
          *nbTreeGridHeaderCellDef
        >
          {{ tableColumnLabels.name }}
        </th>
        <td
          nbTreeGridCell
          *nbTreeGridCellDef="let row"
        >
          <span class="my-characters-table-character-name">{{ row.data.name }}</span>
        </td>
      </ng-container>

      <ng-container [nbTreeGridColumnDef]="tableColumnNames[tableColumnLabels.aiModel]">
        <th
          style="width: 40%;"
          nbTreeGridHeaderCell
          [nbSortHeader]="getSortDirection(tableColumnLabels.aiModel)"
          *nbTreeGridHeaderCellDef
        >
          {{ tableColumnLabels.aiModel }}
        </th>
        <td
          nbTreeGridCell
          *nbTreeGridCellDef="let row;"
        >
          {{ row.data.aiModel }}
        </td>
      </ng-container>

      <ng-container [nbTreeGridColumnDef]="tableColumnNames[tableColumnLabels.actions]">
        <th
          class="my-characters-table-peripheral p-0"
          nbTreeGridHeaderCell
          *nbTreeGridHeaderCellDef
        ></th>
        <td
          class="my-characters-table-peripheral p-0"
          nbTreeGridCell
          *nbTreeGridCellDef="let row"
        >
          <div class="d-flex justify-content-center align-items-center">
            <nb-action
              icon="more-vertical"
              (click)='contextMenuActiveRow = row.data'
              nbContextMenuTrigger="click"
              [nbContextMenu]="getCharacterTableContextItemsForCharacter(row.data)"
              [nbContextMenuTag]="contextMenuTag"
              [nbContextMenuPlacement]="contextItemsPosition"
            />
          </div>
        </td>
      </ng-container>
    </table>
  </div>
  <ng-container *ngIf="characters$ | async as characters">
    <app-card-pagination
      [collectionSize]="characters.length"
      [LSKey]="LSPageSizeKey"
      (onPageChange)="onPageChange($event)"
    />
  </ng-container>
</div>
