import { Component } from '@angular/core';
import { VpsDetailsDto } from '../../models';
import { StreamPlatform } from '../../enum/streams.enum';

@Component({
  selector: 'app-vps-details-modal',
  templateUrl: './vps-details-modal.component.html',
  styleUrls: ['./vps-details-modal.component.scss']
})
export class VpsDetailsModalComponent {

  vpsDetails: VpsDetailsDto;

  constructor() {
  }

  ngOnInit() {
  }

  getPlatformName(platformId: any){
    return StreamPlatform[platformId];
  }

}
