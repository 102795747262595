import { Component } from '@angular/core';
import { DraftVps } from '../../models';
import { NbWindowRef } from '@nebular/theme';
import { VpsService } from '../../services/vps.service';

@Component({
  selector: 'app-new-vps-modal',
  templateUrl: './new-vps-modal.component.html',
  styleUrls: ['./new-vps-modal.component.scss']
})
export class NewVpsModalComponent {
  draftVps: DraftVps;
  onVpsSave: (data: any) => Promise<any>;
  isAllowToUpdateEmail: boolean = true;
  showAccessKey = false
  showLoadingConnectionStatus = false;
  isConnectionToVpsSuccess: boolean | null = null;

  constructor(private windowRef: NbWindowRef, private vpsService: VpsService) {
  }

  closeModal() {
    this.windowRef.close();
  }

  submitForm() {
    this.closeModal()
    this.onVpsSave(this.draftVps).then(console.log);
  }

  onUpdateField(key: string, content: string) {
    this.draftVps = {...this.draftVps, [key]: content}
    this.onChangeVpsAccessToken();
  }

  onChangeVpsAccessToken() {
    if (!this.draftVps?.ip || !this.draftVps?.accessToken) return;
    this.showLoadingConnectionStatus = true;

    this.vpsService.checkVpsConnection(this.draftVps).then((respData) => {
      this.isConnectionToVpsSuccess = respData;
    }).catch((e) => {
      console.log(e);
      this.isConnectionToVpsSuccess = false;
    })
      .finally(() => this.showLoadingConnectionStatus = false);

  }

}
