<app-top-bar [page-title]="pageTitle"/>
<ng-container *ngIf="(pageState$ | async) as pageState" [ngSwitch]="pageState">
  <ng-container *ngSwitchCase="DataState.LOADING">
    <div class="d-flex justify-content-center">
      <div class="spinner-border" role="status">
        <span class="sr-only"></span>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="DataState.LOADED">

    <app-card>
      <div class="admin-setting-buttons">
        <button nbButton (click)="triggerWixCompare()">Trigger Wix Compare</button>
        <button nbButton (click)="triggerUpdateAllVps()">Trigger Update All Vps</button>
      </div>
    </app-card>
  </ng-container>
  <!-- ERROR -->
  <ng-container *ngSwitchCase="DataState.ERROR">
    <div class="d-flex flex-column admin-setting-error">
      <img class="admin-setting-error-img" src="assets/sadcloud.png" alt="No characters">
      <p class="admin-setting-error-text">Failed</p>
    </div>
  </ng-container>

</ng-container>
